import React, { useState } from "react";
import cx from "classnames";

import { PageLink } from "src/components/link";
import { TillykkeLogo, CartIcon } from "src/components/svgs";

import { useCartCount, useToggleCart, useStore } from "src/context/siteContext";

const NAV_LINKS_1 = [
  {
    to: "/collection/all",
    text: "Shop"
  }
];

const NAV_LINKS_2 = [
  {
    to: "/about",
    text: "About"
  },
  {
    to: "/contact",
    text: "Contact"
  }
];

const NavLink = ({
  to,
  text,
  last
}: {
  to: string;
  text: string;
  last: bool;
}) => (
  <PageLink
    to={to}
    className={cx("block md:inline-block px-3 py-3", {
      "md:mr-4": last
    })}
  >
    {text}
  </PageLink>
);

export const Header = () => {
  const { customerName } = useStore();
  const count = useCartCount();
  const toggleCart = useToggleCart();
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <div className="z-10 bg-white border-b sticky top-0">
      <div className="flex flex-wrap justify-between py-4 items-center">
        <PageLink to="/" className="absolute w-full">
          <TillykkeLogo className="h-6 mx:h-8 mx-auto" />
        </PageLink>
        <div className="z-10">
          <button
            className="block md:hidden px-3 py-2 mx-4"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
          <div className="hidden md:block px-4">
            {NAV_LINKS_1.map((link, index) => (
              <NavLink {...link} key={index} />
            ))}
          </div>
        </div>
        <div className="flex items-center z-10">
          <div className="hidden md:block w-auto">
            {NAV_LINKS_2.map((link, index) => (
              <NavLink
                {...link}
                key={index}
                last={index === NAV_LINKS_2.length - 1}
              />
            ))}
          </div>

          <button
            aria-label="cart"
            className="flex px-4"
            onClick={() => toggleCart()}
          >
            <CartIcon className="h-6" />
            <span className="pl-2">{count}</span>
          </button>
        </div>
      </div>
      <div
        className={`${
          isExpanded ? `block ` : `hidden`
        } md:hidden bg-white w-full p-4 border-b z-10`}
      >
        {NAV_LINKS_1.map((link, index) => (
          <NavLink {...link} key={index} />
        ))}
        {NAV_LINKS_2.map((link, index) => (
          <NavLink {...link} key={index} />
        ))}
      </div>
    </div>
  );
};
