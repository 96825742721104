import React, { useState, useEffect } from "react";

import { PageLink } from "src/components/link";
import {
  useCartTotals,
  useCartItems,
  useCheckout,
  useToggleCart
} from "src/context/siteContext";

import { LineItem } from "src/components/cart/lineItem";

export const Cart = () => {
  const lineItems = useCartItems();
  const openCheckout = useCheckout();
  const toggleCart = useToggleCart();
  const { total } = useCartTotals();
  return (
    <div>
      {lineItems.length > 0 ? (
        <div className="bg-white p-4">
          {lineItems.map((item: { id: string; quantity: number }) => (
            <React.Fragment key={item.id + item.quantity}>
              <LineItem {...item} />
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div className="text-center py-4">Cart is Empty</div>
      )}
      <div className="bg-white p-4">
        <div className="uppercase text-xs my-2 w-full text-center">
          Subtotal before taxes & shipping
        </div>
        <div className="w-full items-between p-2">
          {lineItems.length > 0 && (
            <div className="w-full flex justify-between items-center">
              <span className="s24">Subtotal</span>
              <span className="s24">{total}</span>
            </div>
          )}
        </div>
        {lineItems.length < 1 ? (
          <div className="flex justify-center items-center w-full">
            <PageLink
              className="m-2 btn--link"
              onClick={() => toggleCart()}
              to="/collection/all/"
            >
              Get some good gear
            </PageLink>
          </div>
        ) : (
          <button
            onClick={() => openCheckout()}
            type="submit"
            className="w-full btn--primary"
          >
            <span className="block px-4 py-2">Checkout</span>
          </button>
        )}
      </div>
    </div>
  );
};
