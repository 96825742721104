import React, { useEffect } from "react";
import Helmet from "react-helmet";

import { Header } from "src/components/header";
import { Footer } from "src/components/footer";
import { SwitchTransition, Transition } from "react-transition-group";
import { CartDrawer } from "src/components/cartDrawer";
import { PasswordWrapper } from "./password";

const TRANSITION_DURATION = 400;
const TRANSITION_STYLES = {
  default: {
    transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`
  },
  entering: {
    opacity: 0
  },
  entered: {
    opacity: 1
  },
  exiting: {
    opacity: 0
  },
  exited: {
    opacity: 0
  }
};

const Layout = ({
  children,
  siteMetadata,
  location,
  pageContext
}: {
  children: any;
}) => {
  // Render documentation for CMS minus header/footer experience
  if (pageContext.layout === "docs") {
    return <div>{children}</div>;
  }

  if (pageContext.layout === "accounts") {
    return (
      <React.Fragment>
        <Helmet title="Accounts" />
        <Header />
        <div>{children}</div>
        <Footer />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <PasswordWrapper>
        <div className="font-sans min-h-screen flex flex-col">
          <Header />
          <CartDrawer />
          {/*

            Smooth transition credits to Ian Williams: https://github.com/dictions

          */}
          {!/account/.test(location.pathname) ? (
            <SwitchTransition>
              <Transition
                key={location.pathname}
                mountOnEnter={true}
                unmountOnExit={true}
                appear={true}
                timeout={TRANSITION_DURATION}
              >
                {status => (
                  <div
                    style={{
                      ...TRANSITION_STYLES.default,
                      ...TRANSITION_STYLES[status]
                    }}
                  >
                    {children}
                  </div>
                )}
              </Transition>
            </SwitchTransition>
          ) : (
            <div className="flex-auto">
              {children}
              <Footer />
            </div>
          )}
        </div>
        <Footer />
      </PasswordWrapper>
    </React.Fragment>
  );
};

export default Layout;
