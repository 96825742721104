import React from "react";

export const Footer = () => {
  return (
    <footer className="text-center bg-accent text-xs p-4 flex-shrink-0">
      Web design &amp; development by{" "}
      <a
        href="https://amandakievet.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Amanda Kievet
      </a>
      <br />
      Copyright © {new Date().getFullYear()}, Tillykke
    </footer>
  );
};
