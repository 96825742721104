import React, { useEffect } from "react";
import cx from "classnames";

import { Cart } from "src/components/cart";
import { useStore, useToggleCart } from "src/context/siteContext";

import { Close } from "src/components/svgs";

export const CartDrawer = () => {
  const { cartIsOpen } = useStore();
  const toggleCart = useToggleCart();

  useEffect(() => {
    if (typeof window !== "undefined" && !!window.document) {
      const html = document.querySelector("html");
      cartIsOpen
        ? (html.style.overflow = "hidden")
        : (html.style.overflow = "visible");
    }
  }, [cartIsOpen]);

  const trap = cartIsOpen ? (
    <div>
      <div className="relative border-b text-center">
        <h4 className="m-4">Your Cart</h4>
        <button
          type="reset"
          className="absolute top-0 p-2 right-4"
          onClick={() => toggleCart()}
        >
          <Close />
        </button>
      </div>
      <Cart />
    </div>
  ) : (
    false
  );
  return (
    <div
      className={cx("top-0 h-screen w-screen fixed z-10 overflow-scroll", {
        hidden: !cartIsOpen,
        block: cartIsOpen
      })}
      id="container"
    >
      <div className="bg-white sm:max-w-sm w-full right-0 absolute border-l">
        {trap}
      </div>
      <div className="w-full h-full" onClick={() => toggleCart()} />
    </div>
  );
};
