import React, { useState, useEffect } from "react";

import { Close, Minus, Plus } from "src/components/svgs";

import {
  useUpdateItemsFromCart,
  useRemoveItemFromCart,
  client
} from "src/context/siteContext";

export const LineItem = ({
  id,
  title,
  quantity,
  variant: { price, compareAtPrice, image, title: variantTitle },
  customAttributes
}: {
  id: string;
  title: string;
  quantity: number;
  variant: {
    price: string;
    image: string;
    compareAtPrice: string;
    title: string;
  };
  customAttributes: Array<{
    value: string;
  }>;
}) => {
  const updateItemsFromCart = useUpdateItemsFromCart();

  const [stateQuantity, setQuantity] = useState(quantity);
  const removeFromCart = useRemoveItemFromCart();

  const updateQuantity = (qty: number) => {
    updateItemsFromCart({ id, quantity: qty });
    setQuantity(qty);
  };

  const itemImage = client.image.helpers.imageForSize(image, {
    maxWidth: 300,
    maxHeight: 300
  });

  return (
    <div className="flex w-full items-center relative border-b">
      <div className="mr-4">
        <img className="w-40 max-w-none" src={itemImage} alt={title} />
      </div>
      <div className="flex flex-col flex-shrink">
        <div>
          <h5 className="font-serif">{title}</h5>
          {variantTitle !== "Default Title" && <h6>{variantTitle}</h6>}
          {customAttributes.map(attr => (
            <h6 key={attr.key}>
              {attr.key}: <span>{attr.value}</span>
            </h6>
          ))}
        </div>
        <div className="flex justify-between items-center mx-auto my-2">
          <button
            className="appearance-none p-2 mr-2"
            onClick={() =>
              stateQuantity === 1 ? null : updateQuantity(stateQuantity - 1)
            }
          >
            <Minus />
          </button>
          <input
            type="number"
            value={stateQuantity}
            onChange={e => updateQuantity(parseInt(e.currentTarget.value, 10))}
            name="quantity"
            min="1"
            className="text-center border mx-2 w-20"
          />
          <button
            className="appearance-none p-2 ml-2"
            onClick={() => updateQuantity(stateQuantity + 1)}
          >
            <Plus />
          </button>
        </div>
        <div className="text-right">
          {compareAtPrice && (
            <span className="line-through">
              ${parseFloat(compareAtPrice) * stateQuantity} ({stateQuantity})
            </span>
          )}
          <span>
            ${parseFloat(price) * stateQuantity} ({stateQuantity})
          </span>
        </div>
        <button
          type="reset"
          className="absolute right-0 top-0 p-2"
          onClick={() => removeFromCart(id)}
        >
          <Close className="block" />
        </button>
      </div>
    </div>
  );
};
